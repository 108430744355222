import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from '../components/Title'
import SubTitle from '../components/SubTitle'

export const ResourcesPostThanksPageTemplate = ({
  content,
  contentComponent,
  title,
  description,
  image,
  pdfurl,
  relatedurls,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <div>
      <section className="section" style={{ marginTop: 100 }}>
        <div className="container ">
          <div className="columns is-vcentered">
            <div className="column is-5">
              <div style={{ maxWidth: "300px", margin: "auto" }}>
                <PreviewCompatibleImage imageInfo={image} />
              </div>
            </div>
            <div className="column is-7">
              <Title title={title} />
              <p
                className="has-text-weight-bold is-size-4-mobile is-size-4-tablet is-size-4-desktop"
                style={{ margin: "2rem 0" }}
              >
                <strong style={{ color: "#42b4be" }}>{description}</strong>
              </p>
              <a href={pdfurl} target="_blank" rel="noopener noreferrer">
                <img class="alignnone wp-image-5792 size-full" src="/img/btn-download-now.png" alt="" width="236" height="52" pagespeed_url_hash="2617440296" />
              </a>
              <div >
                <PostContent content={content} className="markdown resources" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section resorce-thanks-from" style={{ marginTop: 100 }}>
        <div className="container ">
          <SubTitle title={relatedurls.title} textAlign="center" margin="1rem 0" />
          {
            relatedurls.list.map((url, i) => {
              return (
                <div className="column" style={{ padding: "0 1rem" }}>
                  <Link to={url.url}>
                    <SubTitle title={url.title} textAlign="center" color="#ffffff" />
                  </Link>
                </div>
              )
            })
          }
        </div>
      </section>
    </div >
  )
}

ResourcesPostThanksPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  pdfurl: PropTypes.string,
  relatedurls: PropTypes.object
}

const ResourcesPostThanksPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ResourcesPostThanksPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Resources | Cypress Data Defense">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
        pdfurl={post.frontmatter.pdfurl}
        relatedurls={post.frontmatter.relatedurls}
      />
    </Layout>
  )
}

ResourcesPostThanksPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ResourcesPostThanksPage

export const pageQuery = graphql`
  query ResourcesPostThanksPageByID($id: String!) {
        markdownRemark(id: {eq: $id }) {
        id
      html
      frontmatter {
        title
        description
      image {
        childImageSharp {
      fluid(maxWidth: 600, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
    pdfurl
    relatedurls {
      title
      list {
        title
        url
      }
    }
  }
}
}
`
